import { graphql } from "gatsby"
import React from "react"
import Cards from "../components/Cards"
// import Hero from "../components/Hero"
import Layout from "../layouts/Layout"
import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SiteMetadata title="Home" description="Portfolio of John Doe" />

      {/* <Hero title="Décoration de table" subtitle="Plein de belles choses !" /> */}
      
      <div className="text-center text-3xl sm:text-4xl font-extrabold leading-tight tracking-tight text-pink-600 pb-6"><h1>Décoration de table</h1></div>
      <div className="bg-gray-100 py-12 lg:py-6">
        {data.articles && data.articles.nodes.length > 0 ? (
          <Cards items={data.articles.nodes} />
        ) : (
          <div className="container">No projects found.</div>
        )}
      </div>
      <Newsletter />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQueryTable {
    articles: allContentfulArticles(filter: {
      categorie: {
        eq: "Décoration de table"
      }
    }) {
      nodes {
        ...ArticlesCard
      }
    }
  }
`
